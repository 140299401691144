import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpVerbs, NGHttpService } from './ng-http/ng-http.service';
import User from '../models/user';
import WalletUser from '../models/userwallet.model';
import { first } from 'rxjs';
import { TransferInput } from './models/transfer';
import { SendInput } from './models/send';
import { SwapInput } from './models/swap';
import { DepositInput } from './models/deposit';
import { WithdrawInput } from './models/withdraw';
import { JoinPresale } from './models/JoinPresale';
import { BuyMiningMachine } from './models/BuyMiningMachine';
import { HistoryTransactionMining } from './models/HistoryTransactionMining';
import { TransactionHistoryItem } from '../pages/mining/models/TransactionHistoryItem';
import { JoinPresaleGPM } from './models/JoinPresaleGPM';
import { TransactionHistoryKAS } from './models/TransactionHistoryKAS';
import { TransactionHistorySpot } from './models/TransactionHistorySpot';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  private endPoint: string = '/wallet';
  walletUser: WalletUser = new WalletUser();
  firstInit: boolean=true;

  constructor(
    private readonly ngHttpService: NGHttpService,
    private readonly authService: AuthService,
  ) { }

  initWalletUser(){
    this.walletUser.eur=200.11;
    this.walletUser.gptAvailable=102.77;
    this.walletUser.usdt=99.13;
    this.firstInit=false;
  }

  getWalletUser(id: string) {
    if (this.firstInit) {
        this.initWalletUser();
      }
    return this.walletUser;
  }

  payUSDT(importo: number){
    this.setWalletUser('USDT',importo*(-1));
  }

  payGPT(importo: number){
    this.setWalletUser('GPT',importo*(-1));
  }

  setWalletUser(valuta: string, importo: number){
    console.log("AGGIORNO", valuta, importo)
    switch (valuta) {
      case 'GPT':
        this.walletUser.gptAvailable=this.walletUser.gptAvailable!+importo;
        console.log(this.walletUser.gptAvailable)
        break;
      case 'USDT':
        this.walletUser.usdt=this.walletUser.usdt!+importo;
        break;
      case 'EUR':
        this.walletUser.eur=this.walletUser.eur!+importo;
        break;
      default:
        break;
    }
    console.log("DOPO L?AGGIORNAMNETO",this.walletUser)
  }


  async list(params: any = {}): Promise<Array<User>> {
    return await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}`, params, this.mapToModel, () => this.authService.getToken());
  }

  async get(id: any, params: any={}): Promise<User> {
    return await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}/${id}`, params, this.mapToModel, () => this.authService.getToken());
  }

  async save(model: User): Promise<any> {
    return await this.ngHttpService.execute(HttpVerbs.POST, this.endPoint, model.toObject(true), this.mapToModel, () => this.authService.getToken());
  }

  async createcredit(body: any) {
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/createcredit`, body, null, () => this.authService.getToken());
  }

  async delete(id: any): Promise<boolean> {
    return await this.ngHttpService.execute(HttpVerbs.DELETE, `${this.endPoint}/${id}`, null, null, () => this.authService.getToken());
  }

  async recovery(email: string): Promise<any> {
    return await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}/recoveryPassword/${email}`, null, null);
  }

  async reward100(): Promise<any> {
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/reward100`, null, null);
  }

  async resetcommunitykaspa(): Promise<any> {
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/resetcommunitykaspa`, null, null);
  }


  /*=======================================================================*/
  async transfer(model:TransferInput): Promise<any>{
    //https://gpttech.tboxlab.com:9001/wallet/transfer
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/transfer`, model, null, () => this.authService.getToken());
  }
  async send(model:SendInput):Promise<any>{
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/send`, model, null, () => this.authService.getToken());
  }
  async swap(model:SwapInput):Promise<any>{
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/swap`, model, null, () => this.authService.getToken());
  }

  async joinpresale(model: JoinPresale) {
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/joinpresale`, model, null, () => this.authService.getToken());
  }
  async joinpresalegpm(model: JoinPresaleGPM) {
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/joinpresalegpm`, model, null, () => this.authService.getToken());
  }
  async priceMachine() {
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/priceMachine`, null, null, () => this.authService.getToken());
  }
  async buyMachine(model:BuyMiningMachine) {
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/buyMiningMachine`, model, null, () => this.authService.getToken());
  }
  async priceKAS() {
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/priceKAS`, null, null, () => this.authService.getToken());
  }

  async myPourchase(id: any): Promise<Array<User>> {
    return await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}/mypurchase/${id}`, null, this.mapToModel, () => this.authService.getToken());
  }
  async transactionHistoryKAS(model:TransactionHistoryKAS) {
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/transactionHistoryRevenueKAS`, model, null, () => this.authService.getToken());
  }

  async transactionHistorySpot(model:TransactionHistorySpot) {
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/transactionHistorySpot`, model, null, () => this.authService.getToken());
  }
  async transactionHistoryMining(model:HistoryTransactionMining) {
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/historyTransactionMining`, model, null, () => this.authService.getToken());
  }

  async transactionHistoryRevenueSpot(model:any) {
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/transactionHistoryRevenueSpot`, model, null, () => this.authService.getToken());
  }
  async transactionHistoryNew(model:any) {
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/transactionHistorySpotNew`, model, null, () => this.authService.getToken());
  }

  mapToModel(item: any) {
    let model = User.fromObject(item, new User());
    return model;
  }

}
