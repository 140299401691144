export const environment = {
  production: false,
  MetalPriceAPI_apikey: 'c4ac14e47d547be2682714f88f352ea7',

  // /* PROD servers */
  // apiBaseUrl: 'https://api.gp-technology.com',
  // readGPT: 'https://api.gp-technology.com/setup/getGPT',

  /* DEV servers (VPS di Alessandro)*/
  apiBaseUrl: 'https://gpttech.tboxlab.com:9001',
  readGPT: 'https://gpttech.tboxlab.com:9001/setup/getGPT',
  appUrl: 'https://gpt.tboxlab.com/'

  // /* LOCAL DEV servers */
  // apiBaseUrl: 'https://localhost:8000',
  // readGPT: 'https://localhost:8000/setup/getGPT',

    /* PROD servers
    apiBaseUrl: 'https://api.gp-technology.com',
    readGPT: 'https://api.gp-technology.com/setup/getGPT',
    appUrl: 'https://app.gp-technology.com/'*/
    
}; 
